@import "./assets/styles/common";
@import "./assets/styles/date-time-picker";
@import "./assets/styles/create-event";

html {
  @extend %extend_default;
  font-size: 17px;
  font-family: $font-opensans-light;
}
body {
  @extend %extend_default;
}
* {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  outline: none !important;
}
@media screen and(max-height: 644px) {
  body {
    overflow-y: auto !important;
    > ui-view {
      min-height: 644px !important;
      max-height: 644px !important;
      height: 644px !important;
      display: block !important;
    }
  }
}
@-moz-keyframes spin {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(360deg);
  }
}
