.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  height: 35px !important;
  bottom: -35px !important;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  height: 0;
  border-top: 35px solid rgba(0, 0, 0, 0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid transparent;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.shadow::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
}

.icon, [class*='icon-'] {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  font-style: normal;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 1em;
  width: 1em;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 17px;
  font-family: OpenSans-Regular;
}

ngx-intl-tel-input .intl-tel-input {
  width: 100%;
}

ngx-intl-tel-input .flag-container:not(.open) ul {
  display: none;
}

ngx-intl-tel-input.ng-dirty.ng-invalid input {
  border: 1px solid #ef4c39;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}

.country-dropdown {
  border: none !important;
}

.users-form {
  background: #f4f4f4;
  color: #042f3f;
  border: none;
  height: 45px;
  width: 240px;
  padding: 0 15px;
}

.sign-up-tel-form {
  background: #f4f4f4;
  color: #59595C;
  border: none;
  height: 45px;
  width: 100%;
  padding: 0 15px;
}
