@import "common";


ngx-intl-tel-input {
  .intl-tel-input {
    width: 100%;
  }
  .flag-container {
    &:not(.open) {
      ul {
        display: none;
      }
    }
  }
  &.ng-dirty.ng-invalid {
    input {
      border: 1px solid $color-alerts-active;
    }
  }
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}

.country-dropdown {
  border: none !important;
}

.users-form {
  background: $color-background-main;
  color: $color-text-main;
  border: none;
  height: 45px;
  width: 240px;
  padding: 0 15px;
}

.sign-up-tel-form {
  background: $color-background-main;
  color: $color-deep-gray;
  border: none;
  height: 45px;
  width: 100%;
  padding: 0 15px;
}
