html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  height: 35px !important;
  bottom: -35px !important;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  height: 0;
  border-top: 35px solid rgba(0, 0, 0, 0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid transparent;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.shadow::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
}

.icon, [class*='icon-'] {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  font-style: normal;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 1em;
  width: 1em;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 17px;
  font-family: OpenSans-Regular;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  height: 35px !important;
  bottom: -35px !important;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  height: 0;
  border-top: 35px solid rgba(0, 0, 0, 0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid transparent;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.shadow::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
}

.icon, [class*='icon-'] {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  font-style: normal;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 1em;
  width: 1em;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 17px;
  font-family: OpenSans-Regular;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 15;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 15;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 15;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 15;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow: auto;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  outline: none;
}

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 15;
}

.owl-dt-container-row:last-child {
  border-bottom: none;
}

.owl-dt-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: .5em;
  color: #000000;
  border-bottom: solid 1px #1999d6;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button {
  padding: 0 .8em;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover {
  background-color: #e8f5fb;
}

.owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  flex: 1 1 auto;
  border-top: none;
}

.owl-dt-calendar-multi-year-view {
  display: flex;
  align-items: center;
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
  width: calc(100% - 3em);
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th {
  padding-bottom: .25em;
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
  color: rgba(0, 0, 0, 0.4);
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  text-align: center;
  padding-bottom: 3px;
  padding-top: 3px;
  color: #053040;
  background-color: #bae1f3;
  font-family: OpenSans-Regular;
  font-size: 12px;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
  position: relative;
  height: 1px;
  padding-bottom: .5em;
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
  position: relative;
  height: 0;
  line-height: 0;
  text-align: center;
  outline: 0;
  color: rgba(0, 0, 0, 0.85);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  line-height: 1;
  color: inherit;
  cursor: pointer;
  font-family: OpenSans-Regular;
  font-size: 16px;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: .2;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  color: #1999d6;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #bae1f3;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  cursor: default;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: rgba(0, 0, 0, 0.4);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
  opacity: .4;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.2);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: rgba(31, 152, 213, 0.2);
}

.owl-dt-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: .5em;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: .2em 0;
}

.owl-dt-timer-content .owl-dt-timer-input {
  display: block;
  width: 2em;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: medium none;
  font-size: 1.2em;
  padding: .2em;
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: .6em;
  height: 100%;
  left: -.3em;
}

.owl-dt-timer-divider:before, .owl-dt-timer-divider:after {
  content: '';
  display: inline-block;
  width: .35em;
  height: .35em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: currentColor;
}

.owl-dt-timer-divider:before {
  top: 35%;
}

.owl-dt-timer-divider:after {
  bottom: 35%;
}

.owl-dt-control-button {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  font-family: OpenSans-Light;
  color: inherit;
}

.owl-dt-control-button .owl-dt-control-button-content {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.owl-dt-control-period-button .owl-dt-control-button-content {
  height: 1.5em;
  padding: 0 .5em;
  transition: background-color 100ms linear;
}

.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin: .1em;
  transition: transform 200ms ease;
}

.owl-dt-control-arrow-button {
  color: #1999d6;
}

.owl-dt-control-arrow-button .owl-dt-control-button-content {
  padding: 0;
  width: 1.5em;
  height: 1.5em;
}

.owl-dt-control-arrow-button[disabled] {
  color: rgba(0, 0, 0, 0.4);
  cursor: default;
}

.owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  fill: currentColor;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 240px;
  box-shadow: none;
  background: white;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-inline-container .owl-dt-timer,
.owl-dt-popup-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-timer {
  width: 100%;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 280px;
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;
}

.owl-dt-dialog-container .owl-dt-calendar {
  min-width: 250px;
  min-height: 330px;
  max-width: 750px;
  max-height: 750px;
}

.owl-dt-dialog-container .owl-dt-timer {
  min-width: 250px;
  max-width: 750px;
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 58vh;
    height: 62vh;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 58vh;
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 80vw;
    height: 80vw;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 80vw;
  }
}

.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: #1f98d5;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
  height: 100%;
  width: 100%;
  transition: background-color 100ms linear;
}

.owl-dt-container-control-button:hover .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.1);
}

.owl-dt-container-info {
  padding: 0 .5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.owl-dt-container-info .owl-dt-container-range {
  outline: none;
}

.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
  display: flex;
  justify-content: space-between;
  padding: .5em 0;
  font-size: .8em;
}

.owl-dt-container-info .owl-dt-container-range:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: #1f98d5;
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f98d5;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
  border: 1px solid currentColor;
  transition: background 200ms ease;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
  width: 100%;
  height: 100%;
  padding: .5em;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content, .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
  background: #1f98d5;
  color: #ffffff;
}

.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
  visibility: hidden;
  cursor: default;
}

.owl-dt-inline {
  display: inline-block;
}

.owl-dt-control {
  outline: none;
  cursor: pointer;
}

.owl-dt-control .owl-dt-control-content {
  outline: none;
}

.owl-dt-control:focus > .owl-dt-control-content {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-control:not(:-moz-focusring):focus > .owl-dt-control-content {
  box-shadow: none;
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  height: 35px !important;
  bottom: -35px !important;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  height: 0;
  border-top: 35px solid rgba(0, 0, 0, 0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid transparent;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.shadow::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
}

.icon, [class*='icon-'] {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  font-style: normal;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 1em;
  width: 1em;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 17px;
  font-family: OpenSans-Regular;
}

.create-event-form {
  flex-shrink: 0;
  padding: 10px 16px 10px 0;
  height: 103px;
  width: 260px;
}

.create-event-form .form-section-title {
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
  color: #1999d5;
  font-family: OpenSans-SemiBold;
}

.create-event-form .form-section-title.disabled {
  color: #8ccceb;
}

.create-event-form .form-section-with-icon {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  color: #1999d5;
  font-family: OpenSans-Regular;
}

.create-event-form .form-section.form-section--batch {
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 542px;
}

.create-event-form .form-section.form-section--primary {
  border-right: 1px solid #FFFFFF;
  padding-left: 0px;
  padding-right: 45px;
}

.create-event-form .form-section.form-section--primary .form-section-title {
  color: #1999D6;
  margin-left: 20px;
}

.create-event-form .form-section-item {
  margin-bottom: 30px;
  height: 45px;
}

.create-event-form .form-section-item .input-class {
  width: 100%;
  height: 45px;
  background: #FFFFFF;
  padding: 0 15px;
  border: none;
  outline: none;
}

.create-event-form .form-section-item .input-class.error {
  border: 1px solid #ef4c39;
}

.create-event-form .form-section-item.error {
  color: #ef4c39;
}

.create-event-form .form-section-item .error-message-control {
  color: #ef4c39;
  font-family: OpenSans-Light;
}

.create-event-form .form-section-item.form-section-item--batch-number {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.create-event-form .form-section-item.form-section-item--batch-number > * {
  flex-grow: 1;
}

.create-event-form .form-control button {
  width: 100%;
  height: 100%;
  border: unset;
  background-color: unset;
  display: flex;
  font: 16px OpenSans-SemiBold;
  cursor: pointer;
}

.create-event-form .form-control button:disabled {
  color: #aeaeae;
}

.no-form-section-event-errors {
  position: absolute;
  margin-top: 125px;
  margin-left: 20px;
  width: auto;
}

.no-form-section-event-errors .form-section-item {
  margin-bottom: 30px;
  height: 45px;
}

.no-form-section-event-errors .form-section-item:last-child {
  margin-bottom: 0px;
}

.no-form-section-event-errors .form-section-item .input-class {
  width: 100%;
  height: 45px;
  background: #FFFFFF;
  padding: 0 15px;
  border: none;
  outline: none;
}

.no-form-section-event-errors .form-section-item .input-class.error {
  border: 1px solid #ef4c39;
}

.no-form-section-event-errors .form-section-item.error {
  color: #ef4c39;
}

.form-section-event-errors {
  position: absolute;
  width: auto;
}

.form-section-event-errors .form-section-item {
  margin-bottom: 30px;
  height: 45px;
}

.form-section-event-errors .form-section-item:last-child {
  margin-bottom: 0px;
}

.form-section-event-errors .form-section-item .input-class {
  width: 100%;
  height: 45px;
  background: #FFFFFF;
  padding: 0 15px;
  border: none;
  outline: none;
}

.form-section-event-errors .form-section-item .input-class.error {
  border: 1px solid #ef4c39;
}

.form-section-event-errors .form-section-item.error {
  color: #ef4c39;
}

.calving-form-section-event-errors {
  position: absolute;
  margin-top: -15px;
  width: auto;
}

.calving-form-section-event-errors .form-section-item {
  margin-bottom: 30px;
  height: 45px;
}

.calving-form-section-event-errors .form-section-item:last-child {
  margin-bottom: 0px;
}

.calving-form-section-event-errors .form-section-item .input-class {
  width: 100%;
  height: 45px;
  background: #FFFFFF;
  padding: 0 15px;
  border: none;
  outline: none;
}

.calving-form-section-event-errors .form-section-item .input-class.error {
  border: 1px solid #ef4c39;
}

.calving-form-section-event-errors .form-section-item.error {
  color: #ef4c39;
}

html {
  font-size: 17px;
  font-family: OpenSans-Light;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

@media screen and (max-height: 644px) {
  body {
    overflow-y: auto !important;
  }
  body > ui-view {
    min-height: 644px !important;
    max-height: 644px !important;
    height: 644px !important;
    display: block !important;
  }
}

@-moz-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
