@import 'common';

.create-event-form {
  flex-shrink: 0;
  padding: 10px 16px 10px 0;
  height: 103px;
  width: 260px;
  .form-section-title {
    width: 100%;
    font-size: 16px;
    margin-bottom: 16px;
    color: $color-button-active;
    font-family: $font-opensans-semi-bold;
    &.disabled {
      color: $color-separator-middle-blue;
    }
  }

  .form-section-with-icon {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    color: $color-button-active;
    font-family: $font-opensans-regular;
  }

  .form-section.form-section--batch {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 542px;
  }

  .form-section.form-section--primary {
    border-right: 1px solid $color-white;
    padding-left: 0px;
    padding-right: 45px;
    .form-section-title {
      color: $color-light-blue;
      margin-left: 20px;
    }
  }

  .form-section-item {
    margin-bottom: 30px;
    height: 45px;
    .input-class {
      width: 100%;
      height: 45px;
      background: $color-white;
      padding: 0 15px;
      border: none;
      outline: none;
      &.error {
        border: 1px solid $color-alerts-active;
      }
    }
    &.error {
      color: $color-alerts-active;
    }

    .error-message-control {
      color: $color-alerts-active;
      font-family: $font-opensans-light;
    }
  }

  .form-section-item.form-section-item--batch-number {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    > * {
      flex-grow: 1;
    }
  }

  .form-control button {
    width: 100%;
    height: 100%;
    border: unset;
    background-color: unset;
    display: flex;
    font: 16px $font-opensans-semi-bold;
    cursor: pointer;

    &:disabled {
      color: $color-disable;
    }
  }
}
.no-form-section-event-errors {
  position: absolute;
  margin-top: 125px;
  margin-left: 20px;
  width: auto;

  .form-section-item {
    margin-bottom: 30px;
    height: 45px;

    &:last-child {
      margin-bottom: 0px;
    }

    .input-class {
      width: 100%;
      height: 45px;
      background: $color-white;
      padding: 0 15px;
      border: none;
      outline: none;

      &.error {
        border: 1px solid $color-alerts-active;
      }
    }

    &.error {
      color: $color-alerts-active;
    }
  }
}
.form-section-event-errors {
  position: absolute;
  width: auto;

  .form-section-item {
    margin-bottom: 30px;
    height: 45px;

    &:last-child {
      margin-bottom: 0px;
    }

    .input-class {
      width: 100%;
      height: 45px;
      background: $color-white;
      padding: 0 15px;
      border: none;
      outline: none;

      &.error {
        border: 1px solid $color-alerts-active;
      }
    }

    &.error {
      color: $color-alerts-active;
    }
  }
}
.calving-form-section-event-errors {
  position: absolute;
  margin-top: -15px;
  width: auto;

  .form-section-item {
    margin-bottom: 30px;
    height: 45px;

    &:last-child {
      margin-bottom: 0px;
    }

    .input-class {
      width: 100%;
      height: 45px;
      background: $color-white;
      padding: 0 15px;
      border: none;
      outline: none;

      &.error {
        border: 1px solid $color-alerts-active;
      }
    }

    &.error {
      color: $color-alerts-active;
    }
  }
}
