$color-menu-main: #042f3f;
$color-menu-alt: #294d5a;
$color-menu-active: #55c2b8;
$color-table-expandable: #74c2e6;
$color-table-highlight: rgba(25, 153, 213, .2);
$color-table-expandable-text: #d1eaf6;
$color-button-active: #1999d5;
$color-button-normal: #01646e;
$color-button-normal-alt: #168C84;
$color-button-secondary: #006e64;
$color-button-close: #cacbcd;
$color-disable: #aeaeae;
$color-popup-background: #01646e;
$color-popup-foreground: #55c2b8;
$color-popup-foreground-beef: #3b456f;
$color-notes: #b2c135;
$color-alerts-normal: #168c83;
$color-alerts-active: #ef4c39;
$color-background-main: #f4f4f4;
$color-background-header: #dcdcde;
$color-search-border: #e6e6e6;
$color-background-darker: #ededee;
$color-background-lighter: #ffffff;
$color-background-highlight: #d1ebf7;
$color-border: #d4d4d4;
$color-text-main: #042f3f;
$color-text-alt: #58595b;
$color-text-inverse: #ffffff;
$color-darken: rgba(0, 0, 0, .2);
$color-lighten: rgba(255, 255, 255, .2);
$color-scrollbar: rgba(0, 0, 0, .6);
$color-background-dropdown-disabled: #ebebe4;
$color-invalid: $color-alerts-active;
$color-deep-dark-blue: #053040;
$color-dropdown-grey: #F2F3F3;
$color-seperator-grey: #b3b3b3;
$color-time-picker-border: #B2B2B2;

$color-kpi-regular: #D1EBF7;
$color-kpi-warning: #FFE3BC;
$color-kpi-danger: #FFBAB2;

$color-graph-positive: #B3C235;
$color-graph-intermediate: #FFCC08;
$color-graph-negative: #F04C39;

$color-kpi-border: #007d79;

$color-blue-disabled: #abd7ee;

$color-users-background: #2878a0;
$color-users-foreground: #b0d4f0;

$color-light-green: #d4df28;

// sync status table: row background colors
$color-background-sync-status-High: #ffbab2;
$color-background-sync-status-Low: #d1e8e6;


// re-change colors
$color-turquoise-middle: #56C3B8;
$color-turquoise-light: rgba(186,215,214,.3);
$color-turquoise-dark: #168C84;
$color-marine: #053040;
$color-white: #FFFFFF;
$color-yellow: #FFCC08;
$color-light-blue: #1999D6;
$color-blue-registration: #05303F;
$color-deep-gray: #59595C;
$color-gray-middle: #A8AAAD;

$color-beef-breeding: #6E6EA0;
$color-beef-finishing: #643E17;
$color-beef-finishing-light-brown: #a99595;
$color-beef-wizard-finishing-male: #E4E7F4;
$color-beef-wizard-finishing-female: #F4E6F4;

$color-wizard-finishing-timeline-male: #3B4570;
$color-wizard-finishing-middle-male: #9da2b8;

$color-light-gray: #4a4a4a;
$color-light-purple-graph:#aea9d3;
$color-calving-highlighted: #C8E2EE;

$color-light-orange-graph: #df9226;

$color-gray: #d8d8d8;
$color-border-grey: #dcddde;
$color-remove-icon-grey: #CBCCCE;
$color-cool-gray: #a8aaad;
$color-gray-box-shadow: #B6B6B6;

$color-deep-blue: #2978A0;
$color-popup-blue: #1c92ca;

$color-green-graph: #35BCB3;
$color-red-graph: #F04C39;
$color-beef-line-seperator: #9b95c9;
$color-purple-graph: #AA64AA;
$color-pink-wizard: #c46da9;
$color-light-pink-wizard: #e1c6df;
$color-green-wizard: #9fb103;
$color-light-green-wizard: #e5edd8;
$color-blue-wizard: #32aee2;
$color-light-blue-wizard: #99d6f0;
$color-brown-wizard: #b67317;
$color-light-brown-wizard: #e5ad46;
$color-yellow-graph: #FFCB05;
$color-no-validation: #DF9226;
$color-light-grey: #EDEDED;
$color-registration-disabled: #f9f9f9;


$color-logo-dairy: #168C84;
$color-logo-beef: #6E6EA0;

$color-trial-breeding: #8370b1;

$color-dark-dairy: #006f64;
$color-green-dairy: #73bab5;

$color-column-open: #bde5e1;
$color-column-clear-filters: #d0e8e6;
$color-column-filter-or-sort-active: #e7f8f6;

$color-system-management-alert-high: #FCDBD7;
$color-system-management-alert-medium: #FFCC08;
$color-system-management-alert-low: #D0E8E6;

$color-deep-purple: #B14C9B;
$color-middle-purple: #d8a6cd;
$color-dark-purple: #8D5381;
$color-purple-breeding: #b4a9d0;

$color-beef-finishing-brown: #532b2b;
$color-beef-finishing-dark-brown: #360d0d;

$color-disabled-label: #73bab5;
$color-dark-green: #1b9088;
$color-lighter-green: #168c84;
$color-scroll-green: #0cb0a4;
$color-intermediate-green: #52bfb4;
$color-seperator-blue: #bae1f3;
$color-separator-middle-blue: #8ccceb;
$color-group-item-blue: #e8f5fb;

$color-wizard-disabled: rgba(235, 235, 228, .3);
$color-transparent: rgba(0, 0, 0, 0);

$pwd-info-gray: #ccc;
$pwd-info-light-gray: #ddd;
$pwd-info-light-green: #3a7d34;
$pwd-info-light-red: #ec3f41
