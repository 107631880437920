@font-face {
  font-family: 'OpenSans-Light';
  src: url(opensans-light.woff) format('woff'), url("opensans-light.eot") format('eot'), url("opensans-light.ttf") format('truetype');
}
@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url(opensans-semibold.woff) format('woff'), url("opensans-semibold.eot") format('eot'), url("opensans-semibold.ttf") format('truetype');
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url(opensans-regular.woff) format('woff'), url("opensans-regular.eot") format('eot'), url("opensans-regular.ttf") format('truetype');
}
